<template>
  <div class="container">
    <van-loading
      class="loading"
      color="#9d1d22"
      text-color="#9d1d22"
      v-if="loading"
      size="24px"
      >加载中...</van-loading
    >
    <div v-if="loading == false">
      <van-sticky>
        <van-nav-bar :title="title" left-arrow @click-left="$router.back()" />
      </van-sticky>
      <div class="detail">
        <h2 style="text-align: center" v-if="this.type != 104">
          {{ articles.title }}
        </h2>

<VideoAudioPlayer  :courses=articles></VideoAudioPlayer>    


        <div class="acontent" v-html="articles.detailsHtml"></div>
      </div>
      <van-cell>
        <!--话题-->
        <van-col span="1"></van-col>
        <van-col span="22">
          <div style="margin-top: 10px">
            <van-tag
              @click="toProduct(articles)"
              v-if="
                articles.productName != undefined &&
                articles.productName.length > 0
              "
              size="large"
              color="rgb(255 242 219)"
              text-color="#9d1d22"
              >{{ articles.productName }}
            </van-tag>
          </div>
        </van-col>
      </van-cell>
      <commentScore v-if="this.type > 2000"></commentScore>
      <comment v-if="this.type != 104" :CommentPage="CommentPage"></comment>
    </div>
  </div>
</template>

<script>
import { getArticleInfo } from "@/api/article";
import Comment from "../components/comment";
import CommentScore from "../components/commentScore";
import { getArticleType } from "@/utils/datetype";
import VideoAudioPlayer from "../components/videoAudioPlayer";
export default {
  name: "znarticledetail",
  data() {
    return {
      loading: true,
      type: 100,
      title: "",
      articles: {},

      CommentPage: {
        current: 1,
        pageSize: 10,
        filterData: {
          itemId: "",
          itemType: 1,
        },
      },
    };
  },
  components: {
    Comment,
    CommentScore,
    VideoAudioPlayer,
  },
  updated() {
    this.addpreview();
  },
  created() {
    this.initData();
    this.onLoad();
  },
  watch: {
    $route(to, from) {
      //监听路由是否变化
      if (to.path == "/article/detail") {
          if (to.query != from.query) {
            this.loading = true;
            this.initData();
            this.onLoad();
          }
      }
    },
  },
  mounted() {},

  methods: {
    initData() {
      let type = this.$route.query.type;
      this.type = type;
      this.title = getArticleType(type);
    },
    toProduct(item) {
      this.$router.push({
        path: "/article",
        query: { type: item.typeId, productId: item.productId },
      });
    },
    async onLoad() {
      let para = this.$route.query.para;
      let aresult = await getArticleInfo(para); //({ ...this.page })
      console.log(aresult);
      this.articles = aresult.data.data;
      if (this.type == 104) {
        this.title = this.articles.title;
      }
      this.CommentPage.filterData.itemId = this.articles.id;
      this.loading = false;
    },
   //加图片预览
    addpreview() {
      var listdiv = document.getElementsByClassName("acontent");
      listdiv.forEach((acontentdiv) => {
        var imgs = acontentdiv.getElementsByTagName("img");

        imgs.forEach((element) => {
          element.setAttribute("preview",this.articles.id);
        });

       });
      this.$previewRefresh();
    },


  },
};
</script>


<style lang="less" scoped>
.loading {
  text-align: center;
}
.container {
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
}

.detail {
  padding: 10px 10px 20px;
}

.acontent {
  /deep/ img {
    max-width: 100% !important;
    display: block;
    border: 0;
  }
  /deep/ video {
    max-width: 100% !important;
  }
}
</style>